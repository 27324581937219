import { UserPayload } from '@/src/components/AdyenAddCard/AdyenAddCard.interface';
import { request } from '@/src/lib/api/v4/client/core/request';
import {
  CardsResponse,
  Preference,
  PreferencePostRequest,
  Card,
  V3ApiReturnProps,
} from '@/src/types';
import { OpenAPI as OpenAPIv3 } from '@/src/lib/api/v3';
import { camelCaseIfy } from '@/src/lib';

export class UserPreferencesService {
  public static getUserPreferences = async (
    userId: string,
  ): Promise<Preference[]> => {
    const response: V3ApiReturnProps = await request(OpenAPIv3, {
      method: 'GET',
      url: '/api/user/properties',
      query: { userId },
    });

    //ignore all other 3.0 API response fields
    const typedResponse = camelCaseIfy(response.result) as Preference[];

    console.debug('--- getUserPreferences --- ', typedResponse);

    return typedResponse;
  };

  public static setUserPreference = async (
    preference: PreferencePostRequest,
  ): Promise<Preference> => {
    const response: V3ApiReturnProps = await request(OpenAPIv3, {
      method: 'POST',
      url: '/api/user/properties',
      body: preference,
    });

    const typedResponse = camelCaseIfy(response.result) as Preference;

    console.debug('--- setUserPreference --- ', typedResponse);

    return typedResponse;
  };

  /**
   * getCards Function
   *
   * Overview:
   * The getCards function is an asynchronous function responsible for fetching
   * a user's card information from an API. It makes a POST request to the API
   * with the user's email and the action verb.
   *
   * Parameters:
   * 1. requestBody: An object containing:
   *    - email (string): The email address of the user whose card data is to
   * be fetched.
   *    - verb (string): The action verb, typically describing the type of
   * operation or request (e.g., 'list' for listing cards).
   *
   * Returns:
   * - Promise<CardsResponse>: A promise that resolves to the response from the
   * API. The response is expected to be of the type CardsResponse, which
   * should encapsulate the card data returned from the API.
   *
   */
  public static async getCards({
    requestBody,
  }: {
    requestBody: {
      email?: string;
      verb: string;
    };
  }): Promise<CardsResponse> {
    const response: V3ApiReturnProps = await request(OpenAPIv3, {
      method: 'POST',
      url: '/api/user/cards',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });

    const typedResponse = camelCaseIfy(response.result) as CardsResponse;

    console.debug('--- getCards --- ', typedResponse);

    return typedResponse;
  }

  public static async addCard({
    requestBody,
  }: {
    requestBody: UserPayload;
  }): Promise<Card> {
    const response: V3ApiReturnProps = await request(OpenAPIv3, {
      method: 'POST',
      url: '/api/user/cards',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });

    if (!response.success) {
      throw new Error(response.message);
    }

    const typedResponse = camelCaseIfy(response.result) as Card;

    console.debug('--- addCard --- ', typedResponse);
    return typedResponse;
  }

  public static async logout() {
    await request(OpenAPIv3, {
      method: 'POST',
      url: '/api/user/logout',
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
